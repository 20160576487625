/**
 * @generated SignedSource<<5e45698eca1cbe83dc4e6988fe7ae4d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatsTimeWindow = "ALL_TIME" | "FIVE_MIN" | "ONE_DAY" | "ONE_HOUR" | "SEVEN_DAY" | "SIX_HOUR" | "THIRTY_DAY" | "%future added value";
export type TrendingCollectionSort = "DEFAULT" | "FIFTEEN_MINUTES_SALES" | "FIVE_MINUTES_SALES" | "ONE_DAY_SALES" | "ONE_DAY_VOLUME" | "ONE_DAY_VOLUME_DIFF" | "ONE_HOUR_SALES" | "ONE_HOUR_VOLUME" | "ONE_HOUR_VOLUME_DIFF" | "ONE_MINUTE_SALES" | "SEVEN_DAY_SALES" | "SEVEN_DAY_VOLUME" | "SEVEN_DAY_VOLUME_DIFF" | "SIX_HOUR_SALES" | "SIX_HOUR_VOLUME" | "SIX_HOUR_VOLUME_DIFF" | "THIRTY_DAY_VOLUME" | "THIRTY_DAY_VOLUME_DIFF" | "THIRTY_MINUTES_SALES" | "TOTAL_VOLUME" | "%future added value";
export type RankingsPageTrendingPaginationQuery$variables = {
  categories?: ReadonlyArray<string> | null;
  chain?: ReadonlyArray<| "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value"> | null;
  count?: number | null;
  cursor?: string | null;
  eligibleCount?: number | null;
  floorPricePercentChange: boolean;
  timeWindow?: StatsTimeWindow | null;
  trendingCollectionsSortBy?: TrendingCollectionSort | null;
};
export type RankingsPageTrendingPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RankingsPageTrending_data">;
};
export type RankingsPageTrendingPaginationQuery = {
  response: RankingsPageTrendingPaginationQuery$data;
  variables: RankingsPageTrendingPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categories"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chain"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eligibleCount"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "floorPricePercentChange"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timeWindow"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "trendingCollectionsSortBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "categories",
    "variableName": "categories"
  },
  {
    "kind": "Variable",
    "name": "chains",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "trendingCollectionsSortBy"
  },
  {
    "kind": "Variable",
    "name": "topCollectionLimit",
    "variableName": "eligibleCount"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "statsTimeWindow",
    "variableName": "timeWindow"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "eth",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RankingsPageTrendingPaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RankingsPageTrending_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RankingsPageTrendingPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "trendingCollectionsByCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isVerified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "relayId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategory",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionStatsV2Type",
                    "kind": "LinkedField",
                    "name": "statsV2",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalQuantity",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "WindowCollectionStatsType",
                    "kind": "LinkedField",
                    "name": "windowCollectionStats",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "floorPrice",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOwners",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSupply",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalListed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOfSales",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "volumeChange",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "volume",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "floorPricePercentChange",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "kind": "ScalarField",
                        "name": "floorPricePercentChange",
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "chains",
          "sortBy",
          "categories",
          "topCollectionLimit"
        ],
        "handle": "connection",
        "key": "RankingsPageTrending_trendingCollectionsByCategory",
        "kind": "LinkedHandle",
        "name": "trendingCollectionsByCategory"
      }
    ]
  },
  "params": {
    "cacheID": "5df6de93a2959369684817d22bd69619",
    "id": null,
    "metadata": {},
    "name": "RankingsPageTrendingPaginationQuery",
    "operationKind": "query",
    "text": "query RankingsPageTrendingPaginationQuery(\n  $categories: [CategoryV2Slug!]\n  $chain: [ChainScalar!]\n  $count: Int\n  $cursor: String\n  $eligibleCount: Int\n  $floorPricePercentChange: Boolean!\n  $timeWindow: StatsTimeWindow\n  $trendingCollectionsSortBy: TrendingCollectionSort\n) {\n  ...RankingsPageTrending_data\n}\n\nfragment RankingsPageTrending_data on Query {\n  trendingCollectionsByCategory(after: $cursor, chains: $chain, first: $count, sortBy: $trendingCollectionsSortBy, categories: $categories, topCollectionLimit: $eligibleCount) {\n    edges {\n      node {\n        createdDate\n        name\n        slug\n        logo\n        isVerified\n        relayId\n        ...StatsCollectionCell_collection\n        ...collection_url\n        statsV2 {\n          totalQuantity\n        }\n        windowCollectionStats(statsTimeWindow: $timeWindow) {\n          floorPrice {\n            unit\n            eth\n            symbol\n          }\n          numOwners\n          totalSupply\n          totalListed\n          numOfSales\n          volumeChange\n          volume {\n            unit\n            eth\n            symbol\n          }\n        }\n        floorPricePercentChange(statsTimeWindow: $timeWindow) @include(if: $floorPricePercentChange)\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment StatsCollectionCell_collection on CollectionType {\n  name\n  logo\n  isVerified\n  slug\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "9055dd8eb11c1ea8d650c681a7c0f2bc";

export default node;
